import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Popconfirm,
  Popover,
  Skeleton,
  Table,
  Tag,
  Tooltip,
} from "antd";
import debounce from "lodash.debounce";
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch, connect } from "react-redux";
import { RxQuestionMark } from "react-icons/rx";
import { FaUserAlt } from "react-icons/fa";
import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { AddAgentDrawer } from "../../component";
import { allActions } from "../../Redux/myActions";
import EditAgentDrawer from "../../component/Drawer/EditAgentDrawer";
import TableSkeleton from "../../skeleton/TableSkeleton";
import MainLoader from "../../component/loader/MainLoader";

const mapStateToProps = (store) => {
  const AgentAgencyReducer = store?.AgentAgencyReducer;

  return AgentAgencyReducer;
};

const Agent = (AgentAgencyReducer) => {
  const dispatch = useDispatch();
  const [addAgent, setAddAgent] = useState(false);
  const [editAgent, setEditAgent] = useState(false);
  const {
    loading,
    agentData,
    agentloading,
    agentsearchloading,
    deleteagentloading,
  } = AgentAgencyReducer;
  const [agent, setAgent] = useState({});

  useEffect(() => {
    !editAgent && setAgent({});
  }, [editAgent]);

  useEffect(() => {
    handleFetchAgent();
  }, []);

  const handleFetchAgent = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "agent/list_agent",
          attempt: "FETCH_AGENT_REQUEST_ATTEMPT",
          success: "FETCH_AGENT_REQUEST_SUCCESS",
          failure: "FETCH_AGENT_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "agents",
      link: "/agents",
    },
  ];

  const columns = [
    {
      title: "Photo",
      align: "center",
      width: 110,
      dataIndex: "image_url",
      render: (image_url) => {
        return image_url ? (
          <img
            src={image_url}
            alt=""
            style={{ height: "auto", width: "100%" }}
          />
        ) : (
          <FaUserAlt
            style={{
              fontSize: "4rem",
              padding: "0.5rem",
              color: "white",
              background: "black",
            }}
          />
        );
      },
    },
    {
      title: "Name",
      align: "center",
      render: (data) => (
        <span>
          {data?.first_name} {data?.last_name}
        </span>
      ),
    },
    {
      title: "Email",
      align: "center",
      dataIndex: "email",
    },
    {
      title: "Phone",
      align: "center",
      dataIndex: "mobile",
      render: (mobile) => {
        return mobile ? mobile : "NaN";
      },
    },
    {
      title: "Fax",
      align: "center",
      dataIndex: "fax",
      render: (fax) => {
        return fax ? fax : "NaN";
      },
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "agent_status",
      render: (agent_status) => {
        return agent_status === "Active" ? (
          <Tag size="large" bordered={false} color="success">
            Active
          </Tag>
        ) : (
          <Tag size="large" bordered={false} color="error">
            Inactive
          </Tag>
        );
      },
    },
    {
      title: "Invitation Status",
      align: "center",
      render: () => <span>Pending</span>,
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "action",
      render: (_, data) => {
        return (
          <div
            style={{ justifyContent: "center" }}
            className="action--container"
          >
            <EditOutlined
              onClick={() => {
                setEditAgent(true);
                setAgent(data);
              }}
              className="action--icon"
            />
            <Popover
              trigger="click"
              placement="topRight"
              content={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0.5rem",
                    gap: "1rem",
                  }}
                >
                  Do you want to Send Invitation ?
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: "0.5rem",
                    }}
                  >
                    <Button size="small" type="primary">
                      Send
                    </Button>
                  </div>
                </div>
              }
            >
              <Tooltip placement="bottom" title="Agent Status is pending">
                <RxQuestionMark
                  className="action--icon3"
                  style={{ fontSize: "1.5rem" }}
                />
              </Tooltip>
            </Popover>

            <Popconfirm
              title="Do you want to delete Agent?"
              onConfirm={() => handleDeleteAgent(data)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className="action--icon1" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleDeleteAgent = (data) => {
    dispatch(
      allActions(
        {
          agent_id: Number(data?.id),
        },
        {
          method: "post",
          endPoint: "delete_agent",
          attempt: "DELETE_AGENT_REQUEST_ATTEMPT",
          success: "DELETE_AGENT_REQUEST_SUCCESS",
          failure: "DELETE_AGENT_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            setTimeout(() => {
              handleFetchAgent();
            }, 500);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleChangeAgentInput = (e) => {
    const onlyWhitespace = /^\s*$/.test(e.target.value);

    !onlyWhitespace &&
      dispatch(
        allActions(
          {},
          {
            method: "get",
            endPoint: `agent/list_agent?keyword=${e.target.value}`,
            attempt: "SEARCH_AGENT_REQUEST_ATTEMPT",
            success: "SEARCH_AGENT_REQUEST_SUCCESS",
            failure: "SEARCH_AGENT_REQUEST_FAILURE",

            saveBearerToken: false,
            successInternalState: () => {},
            failureInternalState: () => {},
          }
        )
      );
  };

  const handleFetchAllUser = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "agent/list_agent",
          attempt: "FETCH_AGENT_REQUEST_ATTEMPT",
          success: "FETCH_AGENT_REQUEST_SUCCESS",
          failure: "FETCH_AGENT_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const debounceAgentSearch = debounce(handleChangeAgentInput, 1000);

  if (loading) {
    return (
      <DashboardLayout>
        <Breadcrumb
          style={{ zIndex: "9999" }}
          title="Agents"
          crumbList={crumbList}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "1.5rem",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Skeleton.Input
                round
                style={{ borderRadius: "3rem  0 0 3rem" }}
              />
              <Skeleton.Input round />
              <Skeleton.Input round style={{ borderRadius: "0 3rem 3rem 0" }} />
            </div>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <Skeleton.Button />
              <Skeleton.Button />
            </div>
          </div>
          <TableSkeleton />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      {(deleteagentloading || agentsearchloading) && <MainLoader />}
      <Breadcrumb title="Agents" crumbList={crumbList} />
      <div className="agent--container">
        <div className="agent--container--header">
          <Input
            prefix={
              <SearchOutlined
                style={{
                  color: "gray",
                  fontSize: "1.5rem",
                  marginRight: "1rem",
                }}
              />
            }
            onChange={debounceAgentSearch}
            placeholder="Search agents"
            size="large"
            className="search--agent--input"
          />
          <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            {" "}
            <Button onClick={handleFetchAllUser} className="button--modal">
              <UserOutlined /> All
            </Button>
            <Button onClick={() => setAddAgent(true)} className="button--modal">
              <PlusOutlined /> Agent
            </Button>
          </div>

          <AddAgentDrawer
            handleFetchAgent={handleFetchAgent}
            agentDrawer={addAgent}
            setAgentDrawer={setAddAgent}
          />
        </div>

        <div className="agent--container--body">
          <Table
            bordered
            scroll={{ y: 400, x: 800 }}
            pagination={false}
            columns={columns}
            dataSource={agentData}
          />
        </div>
      </div>
      <EditAgentDrawer
        loading={agentloading}
        agentData={agent}
        agentDrawer={editAgent}
        setAgentDrawer={setEditAgent}
        handleFetchAgent={handleFetchAgent}
      />
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(Agent);
