import cookie from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { async } from "rxjs";

// set in cookie
export const setCookie = (key, value) => {
  if (window !== "undefined") {
    cookie.set(key, value, {
      expires: 1,
    });
  }
};

// remove from cookie
export const removeCookie = (key) => {
  if (window !== "undefined") {
    cookie.remove(key, {
      expires: 1,
    });
  }
};

// get from cookie such as stored token
export const getCookie = (key) => {
  if (window !== "undefined") {
    return cookie.get(key);
  }
};

// will be useful when we need to make request to server
// set in localstorage
export const setLocalStorage = (key, value) => {
  if (window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

// remove from localStoreage
export const removeLocalStorage = (key) => {
  if (window !== "undefined") {
    localStorage.removeItem(key);
  }
};

// authenticate user by passing data to cookie and localStorage
export const authenticateAdmin = (response, next) => {
  setCookie("token", response.data.token);
  setLocalStorage("adminProfilefirstName", response.data.adminFirstName);
  setLocalStorage("adminProfilelastName", response.data.adminLastName);
  next();
};

// access user info from localStorage
export const isAuth = () => {
  const cookieChecked = getCookie("accessToken");
  if (cookieChecked) {
    return true;
  } else {
    return false;
  }
};

// access user info from localStorage
export const isAuth2 = () => {
  if (window !== "undefined") {
    const cookieChecked = getCookie("token");
    if (cookieChecked) {
      if (localStorage.getItem("adminProfilelastName")) {
        return JSON.parse(localStorage.getItem("adminProfilelastName"));
      } else {
        return false;
      }
    }
  }
};

//checking the height
export const IsScrollHeight = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = 200; // Define the scroll height at which the navbar should become sticky
      if (window.pageYOffset >= scrollHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return isSticky;
};

// time difference calculation
export const timeDifferenceCalculation = (value) => {
  const givenDate = new Date(value);
  const currentDate = new Date();
  const timeDiff = currentDate?.getTime() - givenDate?.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  // let resultValue;
  if (daysDiff >= 30) {
    const resultValue = Number(Number(daysDiff) / Number(30)).toFixed(2);
    return Number(resultValue) === 1
      ? resultValue + " " + "month ago"
      : resultValue + " " + "months ago";
  } else {
    return Number(daysDiff) === 1
      ? daysDiff + " " + "day ago"
      : daysDiff + " " + "days ago";
  }
};

export const timeValueFormatter = (value) => {
  const currentTime = new Date();
  const targetTime = new Date(value);
  const timeDiff = currentTime.getTime() - targetTime.getTime();
  const secondsDiff = Math.abs(Math.floor(timeDiff / 1000));

  if (Number(secondsDiff) / 31536000 >= 1) {
    const value = Number(secondsDiff) / 31536000;
    return `${Math.floor(value)} ${Math.floor(value) === 1 ? "year" : "years"}`;
  } else if (Number(secondsDiff) / 2592000 >= 1) {
    const value = Number(secondsDiff) / 2592000;
    return `${Math.floor(value)} ${
      Math.floor(value) === 1 ? "month" : "months"
    }`;
  } else if (Number(secondsDiff) / 604800 >= 1) {
    const value = Number(secondsDiff) / 604800;
    return `${Math.floor(value)} ${Math.floor(value) === 1 ? "week" : "weeks"}`;
  } else if (Number(secondsDiff) / 86400 >= 1) {
    const value = Number(secondsDiff) / 86400;
    return `${Math.floor(value)} ${Math.floor(value) === 1 ? "day" : "days"}`;
  } else if (Number(secondsDiff) / 3600 >= 1) {
    const value = Number(secondsDiff) / 3600;
    return `${Math.floor(value)} ${Math.floor(value) === 1 ? "hour" : "hours"}`;
  } else if (Number(secondsDiff) / 60 >= 1) {
    const value = Number(secondsDiff) / 60;
    return `${Math.floor(value)} ${
      Math.floor(value) === 1 ? "minute" : "minutes"
    }`;
  } else {
    return "Just Now";
  }
};

export const formatDateFunction = (value) => {
  const newDate = new Date(value);
  const year = newDate?.getFullYear();
  const month = newDate?.getMonth() + 1;
  const day = newDate?.getDate();

  const formatDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;

  return formatDate === "NaN-NaN-NaN" ? null : formatDate;
};

export const validateInputNumber = (_, value) => {
  if (value === undefined || (value >= 100000000 && value <= 99999999999)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Number must be between 9 and 11 digits."));
};

export function containsImageExtension(text) {
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|webp)/i;

  return imageExtensions.test(text);
}

export const downloadImage = (imageUrl, filename) => {
  const link = document.createElement("a");
  link.href = imageUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.open(link.href, "_blank");
};
