import axios from "axios";

export const axiosApi = axios.create({
  // baseURL: "https://classibazaar.com/api/v2/",
  baseURL: "https://classibazaar.com.au/api/v2/",
});

export const axiosApi1 = axios.create({
  baseURL: "https://classibazaar.com/api/v1/",
});
