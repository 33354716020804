import React, { useState, useEffect } from "react";
import Card from "antd/es/card/Card";
import { Button, Divider, Select, Skeleton, Timeline } from "antd";
import { Line } from "@ant-design/plots";
import axios from "axios";

import { connect, useDispatch } from "react-redux";
import { allActions } from "../../Redux/myActions";
import { getCookie, timeValueFormatter } from "../../Helpers/FrontendHelper";
import { timeDifferenceCalculation } from "../../Helpers/FrontendHelper";
import { useNavigate, NavLink } from "react-router-dom";

const mapStateToProps = (store) => {
  const { PropertyReducer, ProfileReducer } = store;
  return { PropertyReducer, ProfileReducer };
};

const ActivityAdView = ({ PropertyReducer, ProfileReducer }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { loading, myActivity } = ProfileReducer;
  const { realEstateData, allPropertyData } = PropertyReducer;

  const weekdaysOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  useEffect(() => {
    asyncFetch();
    myActivityLogs();
  }, []);

  const asyncFetch = () => {
    const token = getCookie("accessToken");
    axios
      .get(`https://classibazaar.com.au/api/v2/chart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        const dataEntries = Object.entries(data);
        const sortedDataEntries = dataEntries.sort(
          ([weekdayA], [weekdayB]) =>
            weekdaysOrder.indexOf(weekdayA) - weekdaysOrder.indexOf(weekdayB)
        );
        const sortedDataObject = Object.fromEntries(sortedDataEntries);

        return sortedDataObject;
      })
      .then((data) => {
        const dataArray = Object.keys(data).map((key) => ({
          weekday: key,
          value: parseInt(data[key]),
        }));
        return setData(dataArray);
      })

      .catch((error) => {});
  };

  const handleSelectProperty = (value) => {
    if (value !== "all") {
      const token = getCookie("accessToken");
      axios
        .get(`https://classibazaar.com.au/api/v2/ads_views?post_id=${value}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response?.data;
          const dataEntries = Object.entries(data);
          const sortedDataEntries = dataEntries.sort(
            ([weekdayA], [weekdayB]) =>
              weekdaysOrder.indexOf(weekdayA) - weekdaysOrder.indexOf(weekdayB)
          );
          const sortedDataObject = Object.fromEntries(sortedDataEntries);

          return sortedDataObject;
        })
        .then((data) => {
          const dataArray = Object.keys(data).map((key) => ({
            weekday: key,
            value: parseInt(data[key]),
          }));
          return setData(dataArray);
        })

        .catch((error) => {});
    } else {
      asyncFetch();
    }
  };

  const myActivityLogs = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "myactivity",
          attempt: "FETCH_MYACTIVITY_REQUEST_ATTEMPT",
          success: "FETCH_MYACTIVITY_REQUEST_SUCCESS",
          failure: "FETCH_MYACTIVITY_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const config = {
    data,
    xField: "weekday",
    yField: "value",
    point: {
      size: 5,
      shape: "diamond",
    },
    label: {
      style: {
        fill: "#aaa",
      },
    },
    xAxis: {
      title: {
        text: "Weekday",
      },
    },
    yAxis: {
      title: {
        text: "Views",
      },
    },
  };

  return (
    <div className="activiyadview--container">
      <Card className="activityadview--card">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <h3>Total Views</h3>
          {realEstateData?.realestate_detail?.latest_properties?.length > 0 && (
            <Select
              onChange={(value) => handleSelectProperty(value)}
              style={{
                width: "9rem",
              }}
              placeholder="Property Type"
              options={[
                { label: "All", value: "all" },
                ...realEstateData?.realestate_detail?.latest_properties?.map(
                  (item) => {
                    return {
                      label: item?.title,
                      value: item?.post_id,
                    };
                  }
                ),
              ]}
            />
          )}
        </div>
        <Divider style={{ margin: "1rem 0" }} />
        {data && <Line className="chart--view--box" {...config} />}
      </Card>

      <div className="ad--activity--container">
        <RecentApprovedAdComponent PropertyReducer={PropertyReducer} />

        <Card className="activityadview--card my--activity--log--container">
          <h3 style={{ marginTop: "0.5rem" }}>My Activity Log</h3>
          <Divider style={{ margin: "1rem 0" }} />
          <Timeline
            className="overflow--scroll"
            style={{
              maxHeight: "18rem",
              overflowY: "scroll",
            }}
            items={[
              ...myActivity?.map((item) => {
                return {
                  children: (
                    <div>
                      <span>{item?.title}</span>
                      <p>{timeValueFormatter(item?.created_date)}</p>
                    </div>
                  ),
                };
              }),
            ]}
          />{" "}
        </Card>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(ActivityAdView);

export const RecentApprovedAdComponent = ({ PropertyReducer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { approvedadloading, recentApprovedAd } = PropertyReducer;

  useEffect(() => {
    fetchRecentApprovedAd();
  }, []);

  const fetchRecentApprovedAd = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "approve_ads_new",
          attempt: "FETCH_RECENT_APPROVEDAD_REQUEST_ATTEMPT",
          success: "FETCH_RECENT_APPROVEDAD_REQUEST_SUCCESS",
          failure: "FETCH_RECENT_APPROVEDAD_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleNaviateToPropertyPage = () => {
    localStorage.setItem("sortValue", "Most Recent");
    setTimeout(() => {
      navigate(`/my-property/all`);
    }, 500);
  };

  // if (approvedadloading) {
  //   return <h1>Loading....</h1>;
  // }

  return (
    <Card className="activityadview--card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <h3>Recent Approved Ads</h3>
        <Button onClick={handleNaviateToPropertyPage}>View All</Button>
      </div>
      <Divider style={{ margin: "1rem 0" }} />
      {approvedadloading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            maxHeight: "18rem",
            overflowY: "scroll",
            overflowX: "hidden",
            width: "100%",
          }}
        >
          <ActiveAdSkeleton />
          <ActiveAdSkeleton />
          <ActiveAdSkeleton />
          <ActiveAdSkeleton />
        </div>
      ) : (
        <div
          className="overflow--scroll"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            maxHeight: "18rem",
            overflowY: "scroll",
            overflowX: "hidden",
            width: "100%",
          }}
        >
          {recentApprovedAd?.length > 0 &&
            recentApprovedAd?.map((item) => {
              const value = timeValueFormatter(item?.posted_date);
              return (
                <div className="approvedad--element--container">
                  <img
                    style={{
                      width: "3rem",
                      height: "3rem",
                      objectFit: "cover",
                      borderRadius: "3px",
                      border: "1px solid #80808080",
                      background: "white",
                    }}
                    src={item?.image}
                    alt={item?.title}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.2rem",
                    }}
                  >
                    <NavLink
                      to={`https://classiestate.com.au/property-detail?property_category=${item?.property_category}&indx=${item?.post_id}&key=${item?.title}`}
                    >
                      <span>{item?.title}</span>
                    </NavLink>
                    <span> {value}</span>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </Card>
  );
};

export const ActiveAdSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        background: "#f8f8f8",
        padding: "0.5rem",
        borderRadius: "0.5rem",
        alignItems: "center",
      }}
    >
      <Skeleton.Image style={{ width: "3rem", height: "3rem" }} active={true} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Skeleton.Button
          active={true}
          block={true}
          style={{ width: "8rem", height: "1rem", borderRadius: "3px" }}
        />
        <Skeleton.Button
          active={true}
          block={true}
          style={{ width: "4rem", height: "1rem", borderRadius: "1rem" }}
        />
      </div>
    </div>
  );
};
