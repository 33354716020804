import { message } from "antd";

const INIT_STATE = {
  loading: false,
  emailloading: false,
  agentloading: false,
  agentsearchloading: false,
  inquirystatusloading: false,
  inquirydetailloading: false,
  agentimageloading: false,
  creditloading: false,
  deleteagentloading: false,
  paymentloading: false,
  agentImage: [],
  agentData: [],
  referralData: {},
  agentInquiryList: {},
  inquiryListData: {},
  agentInquiryDetail: {},
  agentInquirySetting: "",
  referLoginUserList: [],
  classiCreidtList: [],
  inquiryCreditData: {},
  agencyInquiry: "",
};

export const AgentAgencyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //reset state
    case "RESET_PROFILE_STATE": {
      return {
        ...state,
        loading: false,
      };
    }

    //get-agents
    case "FETCH_AGENT_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
        agentImage: "",
      };
    }

    case "FETCH_AGENT_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        agentData: action?.payload,
      };
    }
    case "FETCH_AGENT_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //search-agents
    case "SEARCH_AGENT_REQUEST_ATTEMPT": {
      return {
        ...state,
        agentsearchloading: true,
      };
    }

    case "SEARCH_AGENT_REQUEST_SUCCESS": {
      return {
        ...state,
        agentsearchloading: false,
        agentData: action?.payload,
      };
    }
    case "SEARCH_AGENT_REQUEST_FAILURE": {
      return {
        ...state,
        agentsearchloading: false,
      };
    }

    //add otp
    case "COUPON_ADD_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "COUPON_ADD__REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
      };
    }

    case "COUPON_ADD_REQUEST_FAILURE": {
      message.error(action?.payload?.data?.errors);
      return {
        ...state,
        loading: false,
      };
    }

    //referral-LINK
    case "INVITE_LINK_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "INVITE_LINK_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        referralData: action?.payload,
      };
    }

    case "INVITE_LINK_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //invite--email

    case "INVITE_EMAIL_REQUEST_ATTEMPT": {
      return {
        ...state,
        emailloading: true,
      };
    }

    case "INVITE_EMAIL_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);

      return {
        ...state,
        emailloading: false,
      };
    }

    case "INVITE_EMAIL_REQUEST_FAILURE": {
      return {
        ...state,
        emailloading: false,
      };
    }

    //get agent
    case "ADD_AGENT_REQUEST_ATTEMPT": {
      return {
        ...state,
        agentloading: true,
      };
    }

    case "ADD_AGENT_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        agentloading: false,
      };
    }

    case "ADD_AGENT_REQUEST_FAILURE": {
      return {
        ...state,
        agentloading: false,
      };
    }

    // edit agent
    case "EDIT_AGENT_REQUEST_ATTEMPT": {
      return {
        ...state,
        agentloading: true,
      };
    }

    case "EDIT_AGENT_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        agentloading: false,
        agentImage: action?.payload?.source,
      };
    }

    case "EDIT_AGENT_REQUEST_FAILURE": {
      return {
        ...state,
        agentloading: false,
      };
    }

    //offer--message(agent-inquiry)

    case "FETCH_AGENTINQUIRY_LIST_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_AGENTINQUIRY_LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        agentInquiryList: action?.payload,
      };
    }

    case "FETCH_AGENTINQUIRY_LIST_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //find agent inqury(inquiry-list)
    case "FETCH_AGENT_ENQUIRYLIST_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_AGENT_ENQUIRYLIST_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        inquiryListData: action?.payload,
      };
    }

    case "FETCH_AGENT_ENQUIRYLIST_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //change inquiry status

    case "UPDATE_INQUIRY_STATUS_REQUEST_ATTEMPT": {
      return {
        ...state,
        inquirystatusloading: true,
      };
    }

    case "UPDATE_INQUIRY_STATUS_REQUEST_SUCCESS": {
      message.success(action?.payload?.msg);
      return {
        ...state,
        inquirystatusloading: false,
      };
    }

    case "UPDATE_INQUIRY_STATUS_REQUEST_FAILURE": {
      message.error(action?.payload?.data?.message);
      return {
        ...state,
        inquirystatusloading: false,
      };
    }

    //agent enquiry details
    case "FETCH_AGENT_ENQUIRY_DETAILS_REQUEST_ATTEMPT": {
      return {
        ...state,
        inquirydetailloading: true,
      };
    }

    case "FETCH_AGENT_ENQUIRY_DETAILS_REQUEST_SUCCESS": {
      return {
        ...state,
        inquirydetailloading: false,
        agentInquiryDetail: action?.payload,
      };
    }

    case "FETCH_AGENT_ENQUIRY_DETAILS_REQUEST_FAILURE": {
      return {
        ...state,
        inquirydetailloading: false,
      };
    }

    //agent delete
    case "DELETE_AGENT_REQUEST_ATTEMPT": {
      return {
        ...state,
        deleteagentloading: true,
      };
    }

    case "DELETE_AGENT_REQUEST_SUCCESS": {
      message?.success("Agent Deleted Successfully");
      return {
        ...state,
        deleteagentloading: false,
      };
    }

    case "DELETE_AGENT_REQUEST_FAILURE": {
      message?.error(action?.payload?.data?.message);
      return {
        ...state,
        deleteagentloading: false,
      };
    }

    case "SEND_INQUIRY_REQUEST_ATTEMPT": {
      return {
        ...state,
        inquirychatloading: true,
      };
    }

    case "SEND_INQUIRY_REQUEST_SUCCESS": {
      return {
        ...state,
        inquirychatloading: false,
      };
    }
    case "SEND_INQUIRY_REQUEST_FAILURE": {
      return {
        ...state,
        inquirychatloading: false,
      };
    }

    //get inquiry setting
    case "FETCH_INQUIRY_SETTING_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_INQUIRY_SETTING_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        agentInquirySetting: action?.payload,
      };
    }

    case "FETCH_INQUIRY_SETTING_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //post inquiry setting
    case "POST_INQUIRY_SETTING_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "POST_INQUIRY_SETTING_REQUEST_SUCCESS": {
      message.success(action?.payload);
      return {
        ...state,
        loading: false,
      };
    }

    case "POST_INQUIRY_SETTING_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //upload the agent image
    case "UPLOAD_AGENT_IMAGE_REQUEST_ATTEMPT": {
      return {
        ...state,
        agentimageloading: true,
      };
    }

    case "UPLOAD_AGENT_IMAGE_REQUEST_SUCCESS": {
      return {
        ...state,
        agentimageloading: false,
        agentImage: action?.payload?.source,
      };
    }
    case "UPLOAD_AGENT_IMAGE_REQUEST_FAILURE": {
      return {
        ...state,
        agentimageloading: false,
      };
    }

    //referral login user
    case "FETCH_REFERAL_LOGIN_USER_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_REFERAL_LOGIN_USER_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        referLoginUserList: action?.payload,
      };
    }

    case "FETCH_REFERAL_LOGIN_USER_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //classi credit
    case "FETCH_CLASSI_CREDIT_REQUEST_ATTEMPT": {
      return {
        ...state,
        creditloading: true,
      };
    }

    case "FETCH_CLASSI_CREDIT_REQUEST_SUCCESS": {
      return {
        ...state,
        creditloading: false,
        classiCreidtList: action?.payload,
      };
    }

    case "FETCH_CLASSI_CREDIT_REQUEST_FAILURE": {
      return {
        ...state,
        creditloading: false,
      };
    }

    //agency inquiry
    case "FETCH_AGENCY_INQUIRYlIST_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_AGENCY_INQUIRYlIST_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        agencyInquiry: action?.payload,
      };
    }

    case "FETCH_AGENCY_INQUIRYlIST_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //inquiry credit data
    case "FETCH_INQUIRY_CREDIT_DETAILS_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_INQUIRY_CREDIT_DETAILS_SUCCESS": {
      return {
        ...state,
        loading: false,
        inquiryCreditData: action?.payload,
      };
    }
    case "FETCH_INQUIRY_CREDIT_DETAILS_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //loading inquriy payment
    case "LOADING_INQUIRY_PAYMENT": {
      return {
        // paymentloading: true,
        ...state,
        loading: true,
      };
    }

    //post inquiry credit
    case "POST_INQUIRY_CREDIT_DETAILS_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "POST_INQUIRY_CREDIT_DETAILS_SUCCESS": {
      message.success(action?.payload);
      return {
        ...state,
        loading: false,
        paymentloading: false,
      };
    }

    case "POST_INQUIRY_CREDIT_DETAILS_FAILURE": {
      message.error(action?.payload?.data?.message);
      return {
        ...state,
        loading: false,
        paymentloading: false,
      };
    }

    default:
      return state;
  }
};
