import React, { useState } from "react";
import { Input, Form, message, Divider } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { allActions } from "../../Redux/myActions";
import LoaderPrimary from "../../component/loader/LoaderPrimary";
import logo3 from "../../assets/classiEstateAdmin3.png";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";

const mapStateToProps = (store) => {
  const AuthReducer = store.AuthReducer;
  return AuthReducer;
};

const Login = (AuthReducer) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { loading } = AuthReducer;

  const handleSubmitLogin = (values) => {
    dispatch(
      allActions(
        {
          email: values.email,
          password: values.password,
        },
        {
          method: "post",
          endPoint: "authenticate",
          attempt: "LOGIN_REQUEST_ATTEMPT",
          success: "LOGIN_REQUEST_SUCCESS",
          failure: "LOGIN_REQUEST_FAILURE",

          saveBearerToken: true,
          successInternalState: (res) => {
            res?.profile?.is_agency
              ? navigate("/dashboard")
              : message.error(
                  "Your account is  not authorized as Agency, Please verify !"
                );
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const googlelogin = useGoogleLogin({
    onSuccess: (response) => {
      axios
        .get("https://www.googleapis.com/oauth2/v1/userinfo", {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        })
        .then((res) => {
          dispatch(
            allActions(
              {
                first_name: res?.data?.given_name,
                last_name: res?.data?.family_name,
                token: res?.data?.id,
                email: res?.data?.email,
                is_apple: false,
                referal_code: "",
              },
              {
                method: "post",
                endPoint: "social_authenticate",
                attempt: "GOOGLE_LOGIN_REQUEST_ATTEMPT",
                success: "GOOGLE_LOGIN_REQUEST_SUCCESS",
                failure: "GOOGLE_LOGIN_REQUEST_FAILURE",

                saveBearerToken: true,
                successInternalState: (res) => {
                  res?.profile?.is_agency
                    ? navigate("/dashboard")
                    : message.error(
                        "Your account is  not authorized as Agency, Please verify !"
                      );
                },
                failureInternalState: () => {},
              }
            )
          );
        })
        .catch((error) => message.error("Login Failed"));
    },
  });

  return (
    <div className="login--container">
      <div className="login--container--body">
        {loading && <LoaderPrimary />}
        <img
          src={logo3}
          style={{
            padding: "0.3rem",
            width: "300px",
            height: "auto",
          }}
          alt="classiEstate Logo"
        />
        <p className="login--header">Login to your account!</p>
        <Form
          layout="vertical"
          className="form--container"
          onFinish={handleSubmitLogin}
        >
          <Form.Item
            className="login--input"
            name="email"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>Email is required</span>
                ),
              },
            ]}
          >
            <Input
              className="login--input"
              size="large"
              type="email"
              placeholder="Email"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            className="login--input"
            name="password"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>
                    Password is required
                  </span>
                ),
              },
            ]}
          >
            <Input.Password
              className="login--input"
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
            />
          </Form.Item>
          <button
            disabled={loading}
            type="submit"
            style={{ outline: "0", border: "0" }}
            className="button--modal"
          >
            SIGN IN
          </button>
        </Form>
        <NavLink
          style={{ textDecoration: "none" }}
          to="/auth/forget-password"
          className="login--text"
        >
          Forgot Password?
        </NavLink>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              margin: "1rem 0",
            }}
          >
            <span
              style={{
                position: "absolute",
                padding: "0 0.5rem",
                background: "white",
                fontWeight: 600,
                color: "gray",
                zIndex: 1000,
              }}
            >
              OR
            </span>
            <Divider
              style={{
                position: "absolute",
                borderBottom: "1px solid #80808070",
              }}
            />
          </div>
          <button
            style={{
              width: "100%",
              border: "none",
              borderRadius: "1.5rem",
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "1rem",
              padding: "0.5rem 2rem",
              alignItems: "center",
              fontWeight: "semi-bold",
              fontSize: "1rem",
            }}
            onClick={() => googlelogin()}
          >
            <FcGoogle style={{ fontSize: "1.5rem" }} /> Continue with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Login);
