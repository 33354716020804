import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  message,
} from "antd";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import stripe from "../../assets/stripe.png";
import classiWallet from "../../assets/classi-WALLET.png";
import StripeApi from "../../component/payment/StripeApi";

import { connect, useDispatch } from "react-redux";
import { allActions } from "../../Redux/myActions";
import { useEffect } from "react";
import MainLoader from "../../component/loader/MainLoader";

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Inquiry Credit",
    link: "/find-agent-inquiry/inquiry-credits",
  },
];

const mapStateToProps = (store) => {
  const { AgentAgencyReducer } = store;
  return { AgentAgencyReducer };
};

//geting the user data from local storage
const userData = JSON.parse(localStorage.getItem("realestatedata"));

const InquiryCredit = ({ AgentAgencyReducer }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const [form] = Form.useForm();
  const [price, setPrice] = useState("");

  const { loading, inquiryCreditData } = AgentAgencyReducer;
  const [paymentLoading, setPaymentLoading] = useState(false);

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "inquiry_credits",
          attempt: "FETCH_INQUIRY_CREDIT_DETAILS_ATTEMPT",
          success: "FETCH_INQUIRY_CREDIT_DETAILS_SUCCESS",
          failure: "FETCH_INQUIRY_CREDIT_DETAILS_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);
  const onChange = (e) => {
    setValue((prev) => e.target.value);
  };
  const handleSubmitPayment = async (values) => {
    const newDate = new Date(values?.expiry_date);
    const year = newDate?.getFullYear();
    const month = newDate?.getMonth();
    const creditPrice = values?.inquiry_credit.split(",");

    if (value === 1) {
      setPaymentLoading(true);
      try {
        const stripeResponse = await StripeApi?.tokens({
          card: {
            number: values?.card_number,
            year: year,
            month: Number(month) < 10 ? `0${month}` : month,
            cvc: values?.cvc,
          },
        });

        if (!stripeResponse?.id) {
          throw new Error(stripeResponse);
        }

        const customerResposne = await StripeApi?.customers({
          id: stripeResponse?.id,
          email: userData?.user_email,
        });
        if (!customerResposne?.default_source) {
          throw new Error(customerResposne);
        }

        const chargeResponse = await StripeApi?.charges({
          postData: {
            amount: Number(creditPrice[1]),
          },
          orderId: {
            first_name: userData?.first_name,
            last_name: userData?.last_name,
            email: userData?.user_email,
          },
          customers: customerResposne?.id,
        });
        if (!chargeResponse?.data?.id) {
          throw new Error(chargeResponse);
        }
        setPaymentLoading(false);
        chargeResponse.status === 200 &&
          dispatch(
            allActions(
              {
                credits: creditPrice[0],
                price: creditPrice[1],
                payment_method: "Stripe",
                txn_number: chargeResponse?.data?.id,
              },
              {
                method: "post",
                endPoint: "inquiry_credits",
                attempt: "POST_INQUIRY_CREDIT_DETAILS_ATTEMPT",
                success: "POST_INQUIRY_CREDIT_DETAILS_SUCCESS",
                failure: "POST_INQUIRY_CREDIT_DETAILS_FAILURE",

                saveBearerToken: false,
                successInternalState: () => {
                  form.resetFields();
                },
                failureInternalState: () => {},
              }
            )
          );
      } catch (error) {
        setPaymentLoading(false);
        message.error(error?.response?.data?.error?.code);
      }
    } else {
      dispatch(
        allActions(
          {
            credits: creditPrice[0],
            price: creditPrice[1],
            payment_method: "Classi Credit",
            txn_number: "",
          },
          {
            method: "post",
            endPoint: "inquiry_credits",
            attempt: "POST_INQUIRY_CREDIT_DETAILS_ATTEMPT",
            success: "POST_INQUIRY_CREDIT_DETAILS_SUCCESS",
            failure: "POST_INQUIRY_CREDIT_DETAILS_FAILURE",

            saveBearerToken: false,
            successInternalState: () => {
              form.resetFields();
            },
            failureInternalState: () => {},
          }
        )
      );
    }
  };

  const handleCreditChange = (value) => {
    setPrice(value?.split(",")[1]);
  };

  return (
    <DashboardLayout>
      <Breadcrumb title="Inquiry Credit" crumbList={crumbList} />
      {(loading || paymentLoading) && <MainLoader />}
      <div className="inquiry--credit--container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3>Inquriy Credits ( AUD 1 = 10 Classi Credits)</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              color: "#0b3e75ce",
              fontSize: "0.9rem",
              fontWeight: 600,
              gap: "0.5rem",
            }}
          >
            <span>${price} AUD, OR</span>
            <span>{price * 10} Coin</span>
          </div>
        </div>
        <Divider style={{ margin: "0.5rem 0" }} />
        <Form form={form} layout="vertical" onFinish={handleSubmitPayment}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Select Credit option",
              },
            ]}
            label="Inquiry Credits"
            name="inquiry_credit"
          >
            {inquiryCreditData?.packages?.length > 0 && (
              <Select
                onChange={handleCreditChange}
                placeholder="Select Credit"
                options={[
                  ...inquiryCreditData?.packages?.map((item) => {
                    return {
                      label: item?.credits,
                      value: `${item?.credits}, ${item?.price}`,
                    };
                  }),
                ]}
              />
            )}
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Select payment method",
              },
            ]}
            label="Select Payment Type"
            name="payment_type"
          >
            <Radio.Group
              onChange={onChange}
              className="credit--card--layout--container"
            >
              <Radio value={1} className="credit--card--container">
                <img
                  src={stripe}
                  alt=""
                  style={{
                    width: "11.8rem",
                    height: "4.6rem",
                    borderRadius: "0.5rem",
                    marginLeft: "1rem",
                    alignSelf: "center",
                  }}
                />
              </Radio>
              <Radio className="credit--card--container" value={2}>
                <img
                  src={classiWallet}
                  alt=""
                  style={{
                    width: "12rem",
                    borderRadius: "0.5rem",
                    marginLeft: "1rem",
                    height: "5rem",
                  }}
                />
              </Radio>
            </Radio.Group>
          </Form.Item>

          {value === 1 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "1rem 0 0",
              }}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Card Name is required",
                  },
                ]}
                label="Card Name"
                name="card_name"
              >
                <Input placeholder="Card Name" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Card Number is required",
                  },
                ]}
                label="Card Number"
                name="card_number"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="e.g 4242 4242 4242 4242"
                />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "CVC number is required",
                    },
                  ]}
                  style={{ flexBasis: "50%", width: "50%" }}
                  label="CVC"
                  name="cvc"
                >
                  <InputNumber
                    type="number"
                    style={{ width: "50%" }}
                    placeholder="e.g 123"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Expiry Date is required",
                    },
                  ]}
                  style={{ flexBasis: "50%", width: "50%" }}
                  label="Expiry Date"
                  name="expiry_date"
                >
                  <DatePicker placeholder="MM-YYYY" picker="month" />
                </Form.Item>
              </div>
            </div>
          )}

          <Button type="primary" htmlType="submit" className="button--modal">
            Checkout Payment
          </Button>
        </Form>
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(InquiryCredit);
