import React, { useState, useRef, useEffect } from "react";
import { Alert, Card, Space, Input, Button, Form, Divider, QRCode } from "antd";
import Marquee from "react-fast-marquee";
import {
  CopyOutlined,
  MailOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { connect, useDispatch } from "react-redux";
import { allActions } from "../../Redux/myActions";
import InviteLinkSkeleton from "../../skeleton/referral/InviteLinkSkeleton";

const mapStateToProps = (store) => {
  const AgentAgencyReducer = store?.AgentAgencyReducer;
  return { AgentAgencyReducer };
};

const InviteFriend = ({ AgentAgencyReducer }) => {
  const dispatch = useDispatch();
  const { referralData, loading, emailloading } = AgentAgencyReducer;

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "invite_link",
          attempt: "INVITE_LINK_REQUEST_ATTEMPT",
          success: "INVITE_LINK_REQUEST_SUCCESS",
          failure: "INVITE_LINK_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Invite Friend",
      link: "/referral/invite-friend",
    },
  ];

  if (loading) {
    return (
      <DashboardLayout>
        <Breadcrumb title="Invite Friend" crumbList={crumbList} />
        <InviteLinkSkeleton />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <Breadcrumb title="Invite Friend" crumbList={crumbList} />
      <div>
        <Alert
          type="info"
          showIcon
          style={{
            marginTop: "1rem",
            height: "3.5rem",
            fontSize: "1rem",
            fontWeight: "600",
            color: "#0b3f77ce",
            border: "none",
            borderRadius: "0",
          }}
          message={
            <Marquee pauseOnHover gradient={false}>
              Hurry up! Share the classibazaar invitation to your friend and get
              100 Classi credit on sign up of 5 friends.
            </Marquee>
          }
        />
      </div>
      <div className="referral--container">
        <InviteLink referralData={referralData} />
        <InviteGmail loading={emailloading} />
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(InviteFriend);

const InviteLink = ({ referralData }) => {
  const [inputValue, setInputValue] = useState(referralData?.invite_link);
  const inputRef = useRef(null);
  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(inputValue)
      .then(() => {
        inputRef.current.select();
        inputRef.current.style.backgroundColor = inputRef.current.value;
        inputRef.current.style.borderColor = inputRef.current.value;
      })
      .catch((error) => {});
  };
  return (
    <Card className="referral--container--leftchild">
      <h3
        style={{
          fontWeight: "500",
        }}
      >
        Your Invite Link
      </h3>
      <Divider style={{ margin: "0.5rem 0" }} />

      <QRCode errorLevel="H" value={referralData?.qrcode} />
      <div style={{ marginTop: "1.5rem" }}>Share Via: </div>
      <Space.Compact
        style={{
          width: "100%",
          display: "flex",
          gap: "1rem",
        }}
      >
        <Input
          style={{
            borderRadius: "3rem",
          }}
          ref={inputRef}
          value={inputValue}
          readOnly
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Share Link"
          defaultValue="Combine input and button"
        />
        <Button
          onClick={handleCopyLink}
          style={{
            borderRadius: "3rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          type="primary"
          className="button--modal--primary"
        >
          <CopyOutlined />
          Copy
        </Button>
      </Space.Compact>
    </Card>
  );
};

export { InviteLink };

const InviteGmail = ({ loading }) => {
  const dispatch = useDispatch();
  const handleInviteEmail = (values) => {
    const payload = values?.invite_email?.reduce(
      (value, currentValue, index) => {
        if (index === 0) {
          return currentValue?.email;
        } else {
          return value + ", " + currentValue?.email;
        }
      },
      ""
    );

    dispatch(
      allActions(
        { email_list: payload },
        {
          method: "post",
          endPoint: "send_invite_request",
          attempt: "INVITE_EMAIL_REQUEST_ATTEMPT",
          success: "INVITE_EMAIL_REQUEST_SUCCESS",
          failure: "INVITE_EMAIL_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };
  return (
    <Card className="referral--container--rightchild">
      <h3 style={{ fontWeight: 500 }}>
        <MailOutlined /> Invite Gmail Contact
      </h3>
      <Divider style={{ margin: "0.5rem 0" }} />{" "}
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "15rem",
        }}
        onFinish={handleInviteEmail}
        className="invite--friend--form--container"
      >
        <Form.Item
          style={{
            height: "17rem",
            overflowY: "scroll",
            background: "#F5F5F5",
            padding: "1rem",
          }}
          label="Add Email"
        >
          <Form.List name="invite_email">
            {(fields, { add, remove }) => (
              <>
                <Button style={{ marginBottom: "1rem" }} onClick={() => add()}>
                  <PlusOutlined />
                </Button>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    <Space>
                      <Form.Item
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Email is required",
                          },
                        ]}
                        name={[field.name, "email"]}
                        fieldKey={[field.fieldKey, "email"]}
                      >
                        <Input type="email" placeholder="Enter an Email" />
                      </Form.Item>
                    </Space>
                    <Button
                      danger
                      style={{ marginLeft: "1rem" }}
                      onClick={() => remove(field.name)}
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </Form.Item>
        <Button
          // disabled={loading}
          loading={loading}
          htmlType="submit"
          style={{ width: "8rem" }}
          type="primary"
          className="button--modal"
        >
          {loading ? "Loading..." : "Submit"}
        </Button>
      </Form>
    </Card>
  );
};

export { InviteGmail };
