import { Button, Divider, Table } from "antd";
import React, { useEffect, useState } from "react";
import { allActions } from "../../Redux/myActions";
import { useDispatch, connect } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import MainLoader from "../loader/MainLoader";

const mapStateToProps = (store) => {
  const AgentAgencyReducer = store?.AgentAgencyReducer;
  return { AgentAgencyReducer };
};

const QuickInquiry = ({ AgentAgencyReducer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, inquiryListData, inquirystatusloading } = AgentAgencyReducer;
  const [inquiryType, setInquiryType] = useState("");
  const [inquiryStatus, setInquiryStatus] = useState("");
  useEffect(() => {
    fetchPendingInquiryList();
  }, []);

  const fetchPendingInquiryList = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `find_agent_enquiry?api_from=classiEstate&status=Pending&page=1`,
          attempt: "FETCH_AGENT_ENQUIRYLIST_REQUEST_ATTEMPT",
          success: "FETCH_AGENT_ENQUIRYLIST_REQUEST_SUCCESS",
          failure: "FETCH_AGENT_ENQUIRYLIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleUpdateInquiryStatus = (data, { status }) => {
    dispatch(
      allActions(
        {
          is_approved: status,
          enquiry_id: data?.id,
        },
        {
          method: "post",
          endPoint: `action_enquiry`,
          attempt: "UPDATE_INQUIRY_STATUS_REQUEST_ATTEMPT",
          success: "UPDATE_INQUIRY_STATUS_REQUEST_SUCCESS",
          failure: "UPDATE_INQUIRY_STATUS_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: () => {
            setTimeout(() => {
              fetchPendingInquiryList();
            }, 1000);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleNaviagteToEnquiryDetail = (data) => {
    navigate(`/enquiry-detail/${data?.id}`);
    const tempData = JSON.stringify(data);
    localStorage.setItem("inquiryData", tempData);
  };

  const columns = [
    {
      title: "SN",
      align: "center",
      render: (text, record, index) => {
        return <span> {Number(index) + 1}</span>;
      },
    },
    {
      title: "Contact Detail",
      width: 180,
      align: "center",
      render: (data) => {
        return (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
          >
            XXXXX XXXXX
          </div>
        );
      },
    },
    {
      title: "Enquiry For",
      align: "center",
      dataIndex: "enquiry_for",
    },
    {
      title: "Type",
      align: "center",
      dataIndex: "nature_of_property",
    },
    {
      title: "Property Condition",
      align: "center",
      dataIndex: "property_condition",
    },
    {
      title: "Locations",
      width: 200,
      dataIndex: "location",
      align: "center",
      render: (location) => {
        return (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
          >
            {" "}
            {location?.map((item) => (
              <span>{item}</span>
            ))}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => {
        if (status === "Approved") {
          return (
            <Button
              size="small"
              style={{
                background: "#03C988",
                borderColor: "#03C988",
                color: "white",
              }}
            >
              {status}
            </Button>
          );
        } else if (status === "Declined") {
          return (
            <Button
              size="small"
              style={{
                background: "#F24C3D",
                borderColor: "#F24C3D",
                color: "white",
              }}
            >
              {status}
            </Button>
          );
        } else if (status === "Pending") {
          return (
            <Button
              size="small"
              style={{
                background: "#62CDFF",
                borderColor: "#62CDFF",
                color: "white",
              }}
              primary
            >
              {status}
            </Button>
          );
        }
      },
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (_, data) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.4rem",
              justifyContent: "center",
            }}
          >
            <ExclamationCircleOutlined
              onClick={() => handleNaviagteToEnquiryDetail(data)}
              className="inquiry--action--icon"
            />
            {data?.status?.toLowerCase() === "pending" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.4rem",
                }}
              >
                <Button
                  size="small"
                  style={{
                    borderColor: "#03C988",
                    color: "#03C988",
                  }}
                  onClick={() => {
                    setInquiryType((prev) => data?.id);
                    setInquiryStatus("approve");
                    handleUpdateInquiryStatus(data, { status: 1 });
                  }}
                >
                  Approve
                </Button>
                <Button
                  size="small"
                  style={{
                    borderColor: "#F24C3D",
                    color: "#F24C3D",
                  }}
                  loading={
                    inquiryListData &&
                    inquiryType === data?.id &&
                    inquiryStatus === "decline"
                  }
                  onClick={() => {
                    setInquiryType((prev) => data?.id);
                    handleUpdateInquiryStatus(data, { status: 0 });
                  }}
                >
                  Decline
                </Button>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="quick--inquiry--container">
      {inquirystatusloading && <MainLoader />}
      <h3>Quick Inquiry</h3>
      <Divider style={{ margin: "0" }} />
      <div className="quick--inquiry--inner--container">
        {inquiryListData?.enquiries && (
          <Table
            bordered
            className="ant-table-body"
            scroll={{ x: 1200, y: 600 }}
            pagination={false}
            dataSource={[...inquiryListData?.enquiries]}
            columns={columns}
          />
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(QuickInquiry);
